<template>
    <div id="registration-password-setup">
        <h2 class="page-title">
            Добро пожаловать, {{userData.username}}!
        </h2>
        <div class="c-wrapper">
            <div class="form-wrapper">
                <ValidationObserver v-slot="{invalid}" ref="form">
                    <form class="u-col-12" v-on:submit.prevent="handleSubmit()">
                        <div class="form-group">
                            <label for="password">
                                Придумайте, пожалуста, пароль:
                            </label>
                            <ValidationProvider rules="required|min-max:8,15|strong|password:@confirm" v-slot="{classes, errors}">
                                <div class="e-wrapper" v-show="isSubmitted && errors.length">
                                    <div class="err-wrapper">
                                        <div v-for="err in errors">{{ err }}</div>
                                    </div>
                                    <!--<span class="errors-tooltip" id="err-popover"></span>
                                    <b-tooltip target="err-popover" placement="left" variant="danger">
                                        <div class="err-wrapper">
                                            <div v-for="err in errors">{{ err }}</div>
                                        </div>
                                    </b-tooltip>-->
                                </div>
                                <div class="input-wrapper">
                                    <input ref="password" id="password" type="password" v-model="userData.password" name="password" class="form-control" :class="isSubmitted ? classes : ''" placeholder="Пароль"/>
                                </div>
                                <password-strength id="password" :password="userData.password"></password-strength>
                            </ValidationProvider>
                        </div>
                        <div class="form-group">
                            <ValidationProvider name="confirm" rules="required|min-max:8,15" v-slot="{classes, errors}">
                                <div class="e-wrapper">
                                    <div class="err-wrapper" v-if="isSubmitted">
                                        <div v-for="err in errors">{{ err }}</div>
                                    </div>
                                </div>
                                <div class="input-wrapper">
                                    <input id="confirm" type="password" v-model="userData.password_conf" name="confirm" class="form-control" v-bind:class="isSubmitted ? classes : 'submitted'" placeholder="Подтверждение пароля"/>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="controls-wrapper">
                            <button class="def-btn submit-btn" type="submit">Войти</button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
            <div class="password-details">
                Длина пароля должна быть не менее 8 символов. Пароль должен состоять из букв латинского алфавита (A-z), арабских цифр (0-9) и специальных символов. Буквенная часть пароля должна содержать как строчные, так и прописные (заглавные) буквы.
            </div>
        </div>
    </div>
</template>

<script>
    import passwordStrength from "../../../../components/_shared/PasswordStrength";
    import {authService} from "@/_api";
    export default {
        name: 'registration-password-setup',
        data() {
            return {
                isSubmitted: false,
                userData: {
                    password: '',
                    password_conf: '',
                    key: '',
                    username: ''
                }
            }
        },
        components: {
            'password-strength': passwordStrength
        },
        computed: {},
        methods: {
            handleSubmit() {
                this.isSubmitted = true;
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        return;
                    } else {
                        authService.setPassword(this.userData).then((res) => {
                            if (res && res.data.api_key) {
                                this.$store.dispatch('authentication/login', res.data);
                                this.$store.dispatch('alert/success', 'Пароль создан', { root: true });
                            }
                        });
                    }
                })
            },
        },
        created () {},
        mounted() {
            // // console.log(this.$route.query);
            if (!this.$route.query || (this.$route.query && !this.$route.query.api_key && !this.$route.query.user_id) ) {
                // this.$router.push('/registration/step-one');
            } else {
                this.userData['username'] = this.$route.query.name;
                this.userData['key'] = this.$route.query.api_key;
                this.userData['id'] = this.$route.query.user_id;

                localStorage.removeItem('uReg');
                // // console.log(localStorage);
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../../../assets/styles/_vars";
    #registration-password-setup {
        .c-wrapper {
            background-color: #F8F8F8;
            -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            /*padding: 31px 249px 49px 117px!important;*/
            padding: 48px 57px 48px 117px;
            .form-wrapper {
                width: 45%!important;
                form {
                    max-width: 324px;
                    background-color: transparent!important;
                    box-shadow: none!important;
                    // margin: 31px 249px 49px 117px!important;
                    padding: 0!important;
                    label {
                        font-size: 18px!important;
                        text-transform: none!important;
                        font-weight: 400!important;
                        margin-bottom: 20px;
                        color: #666666;
                    }
                    .form-group {
                        margin-bottom: 20px;
                        text-align: left;
                    }
                    .submit-btn {
                        width: 100%;
                        padding: 6px 37px;
                    }
                    .e-wrapper {
                        position: inherit;
                        margin-bottom: 5px;
                    }
                }
            }
            .password-details {
                width: 50%;
                color: #71809C;
                font-size: 12px;
                padding-top: 46px;
            }
        }
    }
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #registration-password-setup {
            .page-title {
                font-size: 20px;
            }
            .c-wrapper {
                padding: 15px 15px 15px 15px;
                flex-wrap: wrap;
                .form-wrapper {
                    width: 100%!important;
                    form {
                        max-width: 320px;
                        margin: 0 auto 20px auto;
                        label {
                            font-size: 15px!important;
                            margin-bottom: 5px;
                        }
                        .form-group {
                            margin-bottom: 20px;
                            text-align: left;
                            input {
                                font-size: 14px;
                            }
                        }
                        .submit-btn {
                            max-width: 320px;
                            margin: 0 auto;
                            padding: 4px 30px;
                            font-size: 15px;
                        }
                        .e-wrapper {
                            position: inherit;
                            margin-bottom: 5px;
                        }
                    }
                }
                .password-details {
                    width: 100%;
                    padding: 0;
                    max-width: 320px;
                    margin: 0 auto;
                }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {}
</style>
