<template>
    <transition name="fade">
        <div id="password-strength">
            <div class="easy-password indicator-wrapper" :class="passwordStrengthCalc">
                <transition name="fade">
                    <div class="strength-indicator" v-if="passwordStrengthCalc === 'easy' || !password ">Easy</div>
                </transition>
            </div>
            <div class="medium-password indicator-wrapper" :class="passwordStrengthCalc">
                <transition name="fade">
                    <div class="strength-indicator" v-if="passwordStrengthCalc === 'medium' || !password ">Medium</div>
                </transition>
            </div>
            <div class="strong-password indicator-wrapper" :class="passwordStrengthCalc">
                <transition name="fade">
                    <div class="strength-indicator" v-if="passwordStrengthCalc === 'strong' || !password ">Strong</div>
                </transition>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'PasswordStrength',
        props: {
            id: {
                type: String,
                required: true
            },
            password: {
                type: String,
                required: true
            }
        },
        computed: {
            passwordStrengthCalc() {
                if (this.strongRegex.test(this.password)) {
                    return 'strong'
                } else if (this.mediumRegex.test(this.password)) {
                    return 'medium'
                } else if (!this.mediumRegex.test(this.password) && !this.strongRegex.test(this.password) && this.password) {
                    return 'easy'
                }
            }
        },
        data() {
            return {
                passwordText: '',
                strongRegex: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"),
                mediumRegex: new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})")
            }
        },
        watch: {

        },
        mounted() {

        },
        methods: {

        }
    }

</script>

<style lang="less" scoped>
    @import "../../assets/styles/_vars";
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    #password-strength {
        margin-top: 8px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: nowrap;
        .indicator-wrapper {
            width: 100%;
            padding-top: 9px;
            position: relative;
            &:before {
                content: '';
                height: 4px;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
            &.easy-password {
                &:before {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    background-color: @pwd_easy;
                }
                .strength-indicator {
                    background-color: @pwd_easy;
                    &:before {
                        background-color: @pwd_easy;
                    }
                }
                &.easy {
                    &~div {
                        &:before {
                            background-color: transparent;
                        }
                    }
                }
                &.medium {
                    &:before {
                        background-color: @pwd_medium;
                    }
                }
                &.strong {
                    &:before {
                        background-color: @pwd_strong;
                    }
                }
            }
            &.medium-password {
                &:before {
                    background-color: @pwd_medium;
                }
                .strength-indicator {
                    background-color: @pwd_medium;
                    &:before {
                        background-color: @pwd_medium;
                    }
                }
                &.medium {
                    &~div {
                        &:before {
                            background-color: transparent;
                        }
                    }
                }
                &.strong {
                    &:before {
                        background-color: @pwd_strong;
                    }
                }
            }
            &.strong-password {
                &:before {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    background-color: @pwd_strong;
                }
                .strength-indicator {
                    background-color: @pwd_strong;
                    &:before {
                        background-color: @pwd_strong;
                    }
                }
            }
            .strength-indicator {
                box-shadow: 0 2px 4px rgba(39, 40, 51, 0.2);
                border-radius: 5px;
                color: #ffffff;
                text-align: center;
                margin: 0 5px;
                font-size: 14px;
                padding: 7px 0;
                position: relative;
                &:before {
                    content: '';
                    width: 5px;
                    height: 5px;
                    background-color: red;
                    position: absolute;
                    top: -2.5px;
                    left: 50%;
                    margin-left: -2.5px;
                    transform: rotate(45deg);
                }
            }
        }
    }
</style>
